<template>
  <v-card flat>
    <v-card-text>
      <v-layout
        :justify-end="elevator.unloadedAt"
        :justify-center="!elevator.unloadedAt"
        align-center
        class="d-flex flex-column"
      >
        <v-btn
          :disabled="elevator.audit.editable"
          color="primary"
          @click="$refs.unloadForm.open(elevator)"
        >
          <v-icon left>mdi-package-down</v-icon>
          <span v-if="elevator.unloadedAt">Modifier le remplissage</span>
          <span v-else>Remplir</span>
        </v-btn>
        <v-alert v-if="elevator.audit.editable" type="warning" class="mt-5">
          Vous devez d'abord finaliser l'audit.
        </v-alert>
      </v-layout>
      <v-list v-if="elevator.unloadedAt">
        <v-subheader>
          Bennes où ont été déchargés les déchets le
          {{ elevator.unloadedAt | formatDate("datetime") }}
        </v-subheader>
        <v-divider />
        <template v-for="container in containers">
          <v-list-item :key="container.id">
            <v-list-item-icon>
              <v-icon>mdi-trash-can</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ container.code }} - Cycles {{ container.cycleSummary }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ container.defaultWasteType.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ container.formula }}
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="container.id + '_d'" />
        </template>
      </v-list>
      <UnloadElevator ref="unloadForm" @finished="$emit('reload')" />
    </v-card-text>
  </v-card>
</template>

<script>
import UnloadElevator from "@/components/Elevators/UnloadElevator";
import rfdc from "rfdc";
export default {
  components: { UnloadElevator },
  props: {
    elevator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    containers() {
      let containers = {};
      this.elevator.fillingParts.forEach((part) => {
        let c = part.containerCycle.container;
        if (!containers[c.id]) {
          containers[c.id] = rfdc()(c);
        }
        if (!containers[c.id].addedMass) {
          containers[c.id].addedMass = 0;
        }
        containers[c.id].addedMass += part.mass;
        if (!containers[c.id].cycles) {
          containers[c.id].cycles = [];
        }
        containers[c.id].cycles.push({
          id: part.containerCycle.id,
          number: part.containerCycle.number,
          mass: part.mass,
          isTransition: !!part.nextFillingPart,
        });
      });
      Object.keys(containers).forEach((id) => {
        let container = containers[id];
        containers[id].cycleSummary = new Intl.ListFormat("fr").format(
          container.cycles.map((c) => "n°" + c.number.toString())
        );
        let formula = container.addedMass + " kg";
        if (container.cycles.length > 1) {
          formula += " = ";
          formula += container.cycles
            .map((c) => c.mass.toString() + " kg ")
            .join(" + ");
        }
        containers[id].formula = formula;
      });
      return containers;
    },
  },
};
</script>
